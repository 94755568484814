@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://fonts.googleapis.com/css2?family=Amiko&family=Raleway&family=Rambla&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	scroll-behavior: smooth;
}

body {
	background-image: url('../src/Assets/img/Unique\ Round\ Logo\ \(1\).png');
	background-repeat: repeat;
	background-size: 20%;
}

a {
	text-decoration: none;
	font-family: 'Rambla', sans-serif;
}

li {
	list-style: none;
}

button {
	display: block;
	text-decoration: none;
	cursor: pointer;
	border: none;
	font-family: 'Amiko', sans-serif;
}

p,
span,
label,
div {
	font-family: 'Amiko', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Raleway', sans-serif;
}
